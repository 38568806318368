import React, { useState } from 'react';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        if (visible) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className='scroll-button' style={{ transition:'0.2s', position:'fixed', alignItems:'center', display:'flex', justifyContent:'center', border:'1px solid #d5d9d9', opacity:visible?'1':'0', cursor:visible?'pointer':'' ,bottom:20, right:20, boxShadow: 'rgba(213, 217, 217, .5) 0 2px 5px 0', zIndex:'1000', borderRadius:500, width:50, height:50 }} onClick={scrollToTop}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15.166" height="18.4473">  <g>   <rect height="18.4473" opacity="0" width="15.166" x="0" y="0" />   <path d="M7.40234 18.4473C7.91016 18.4473 8.27148 18.0957 8.27148 17.5879L8.27148 4.72656L8.17383 1.81641L7.61719 2.01172L11.123 5.84961L13.3496 8.03711C13.5059 8.19336 13.7305 8.27148 13.9648 8.27148C14.4531 8.27148 14.8047 7.90039 14.8047 7.42188C14.8047 7.1875 14.7266 6.98242 14.541 6.78711L8.05664 0.292969C7.87109 0.0976562 7.64648 0 7.40234 0C7.1582 0 6.93359 0.0976562 6.74805 0.292969L0.273438 6.78711C0.0878906 6.98242 0 7.1875 0 7.42188C0 7.90039 0.351562 8.27148 0.839844 8.27148C1.07422 8.27148 1.30859 8.19336 1.45508 8.03711L3.68164 5.84961L7.17773 2.01172L6.63086 1.81641L6.5332 4.72656L6.5332 17.5879C6.5332 18.0957 6.89453 18.4473 7.40234 18.4473Z" fill="#000000" />  </g> </svg>
        </div>
    );
}

export default ScrollButton; 

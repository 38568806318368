import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';

const Wrapper = (props) => {
    const { setIsPaymentDone } = props;
    const { clientSecret } = props;
    const stripe = useStripe();
    const elements = useElements()
    const [message, setMessage] = useState(null);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);

    useEffect(() => {

        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );


        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handlePaymentSubmit = async (e) => {

        elements.submit()
        e.preventDefault();

        if (!stripe) {
            return;
        }

        setIsPaymentLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://barbotine.xyz",
            },
            redirect: 'if_required',
            clientSecret: clientSecret,
        });

        if (error) {
            setIsPaymentDone(false);
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }
        else {
            setMessage("Payment successful!");
            setIsPaymentDone(true);
        }
        setIsPaymentLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handlePaymentSubmit} className="payment-form">
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button className="home-link" style={{ cursor:'pointer', fontFamily:'Open Sans', fontSize:'18px',marginTop: 30, padding: '10px 20px', alignSelf: 'center', justifySelf:'center' }} disabled={isPaymentLoading || !stripe} id="submit">
                <span id="button-text">
                    {isPaymentLoading ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={201} height={201} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '25', height: '25' }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="5" stroke="#000000" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Pay now"}</span>
            </button>
            {message && <div style={{ alignSelf: 'center', textAlign: 'center', marginTop:20, color:'red' }} id="payment-message">{message}</div>}
        </form>)
}
export default Wrapper
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header";
import Marquee from "./components/Marquee";

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Marquee text={'Have some money to invest in crypto? Invest it in quantitative portfolio trading with our partner!'} />
      <Header />
      <App />
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals();

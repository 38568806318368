import React from 'react';
import { Helmet } from 'react-helmet';
import '../main.css';
import Footer from '../components/Footer';
import { useState } from 'react'
import ScrollButton from '../components/ScrollButton';

function Legal() {

    const [legalNotice, setLegalNotice] = useState(false)
    const [PrivacyPolicy, setPrivacyPolicy] = useState(false)
    const [SalesTerms, setSalesTerms] = useState(false)
    const [Tos, setTos] = useState(false)

    return (
        <div className='page-container'>
            <Helmet>
                <title>Legal docs | Barbotine</title>
                <meta property="og:title" content="Legal docs | Barbotine" />
            </Helmet>
            <ScrollButton />
            <div className='main-container' style={{ marginTop: 100, marginBottom:100 }}>
                <div className='legal-div'>
                    <div onClick={() => { setLegalNotice(!legalNotice) }} className='legal-title-div'>
                        <h1 style={{ margin: 0, fontWeight: 500 }}>Legal notice</h1>
                        <svg style={{ transition: '0.1s', transform: !legalNotice ? 'rotate(-90deg)' : '' }} fill="#000000" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /></svg>
                    </div>
                    <div className={`legal-content ${legalNotice ? 'opened' : ''}`}>
                        {legalNotice && <div className='legal-main-container'>
                            <h2 className='legal-subtitle'>1. Website owner</h2>
                            <p className='legal-p'>This website is owned by Barbotine and hosted by Netlify (www.netlify.com).</p>
                            <h2 className='legal-subtitle'>2. Contact Information</h2>
                            <p className='legal-p'>You can contact us at:</p>
                            <p className='legal-p'>Email: legal@barbotine.xyz or nelso@barbotine.xyz</p>
                            <p className='legal-p'>Address: 6 rue Jean-Pierre Timbaud, 75011 Paris, France</p>
                            <h2 className='legal-subtitle'>3. Copyright Notice</h2>
                            <p className='legal-p'>All content on this website, including text, graphics, logos, images, and software, is the property of Barbotine or its licensors and is protected by French and international copyright laws.</p>
                            <h2 className='legal-subtitle'>4. Trademark notice</h2>
                            <p className='legal-p'>Barbotine is a trademark of Barbotine SASU. All other trademarks, service marks, and logos used on this website are the property of their respective owners.</p>
                        </div>}
                    </div>
                </div>
                <div className='legal-div'>
                    <div onClick={() => { setPrivacyPolicy(!PrivacyPolicy) }} className='legal-title-div'>
                        <h1 style={{ margin: 0, fontWeight: 500 }}>Privacy policy</h1>
                        <svg style={{ transition: '0.1s', transform: !PrivacyPolicy ? 'rotate(-90deg)' : '' }} fill="#000000" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /></svg>
                    </div>
                    <div className={`legal-content ${PrivacyPolicy ? 'opened' : ''}`}>
                        {PrivacyPolicy && <div className='legal-main-container'>
                            <h2 className='legal-subtitle'>1. Introduction</h2>
                            <p className='legal-p'>Barbotine ("we," "us," or "our") is committed to protecting the privacy of our users ("you" or "your"). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website or purchase our products.</p>
                            <h2 className='legal-subtitle'>2. Information We Collect</h2>
                            <p className='legal-p'>Personal Information: Information that can be used to identify you, such as your name, email address, GitHub username.</p>
                            <p className='legal-p'>Usage Data: Information about how you use our website and products, such as your IP address, browser type, operating system, and pages visited.</p>
                            <h2 className='legal-subtitle'>3. How We Use Your Information</h2>
                            <p className='legal-p'>We may use your information for the following purposes: To process your orders and provide you with our products. To improve our website and products. In this case, we only use Google Analytics, where every data is anonymized. To comply with legal obligations.</p>
                            <h2 className='legal-subtitle'>4. Analytics</h2>
                            <p className='legal-p'>We use Google Analytics to collect and analyze usage data. All data collected through Google Analytics is completely anonymized and used to understand how our website is used and to improve our services.</p>
                            <h2 className='legal-subtitle'>5. Disclosure of Your Information</h2>
                            <p className='legal-p'>We may disclose your information to third parties in the following circumstances:</p>
                            <p className='legal-p'>To comply with legal obligations or requests from law enforcement.</p>
                            <p className='legal-p'>To protect the rights, property, or safety of ourselves or others.</p>
                            <h2 className='legal-subtitle'>6. Data Security</h2>
                            <p className='legal-p'>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>
                            <h2 className='legal-subtitle'>7. Your Privacy Rights</h2>
                            <p className='legal-p'>You have the right to access, correct, or delete your personal information. You may also object to the processing of your personal information. To exercise these rights, please contact us at legal@barbotine.xyz.</p>
                            <h2 className='legal-subtitle'>8. Changes to this Privacy Policy</h2>
                            <p className='legal-p'>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated Privacy Policy on our website.</p>
                        </div>}
                    </div>
                </div>
                <div className='legal-div'>
                    <div onClick={() => { setSalesTerms(!SalesTerms) }} className='legal-title-div'>
                        <h1 style={{ margin: 0, fontWeight: 500 }}>Sale terms</h1>
                        <svg style={{ transition: '0.1s', transform: !SalesTerms ? 'rotate(-90deg)' : '' }} fill="#000000" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /></svg>
                    </div>
                    <div className={`legal-content ${SalesTerms ? 'opened' : ''}`}>
                        {SalesTerms && <div className='legal-main-container'>
                            <h2 className='legal-subtitle'>1. Introduction</h2>
                            <p className='legal-p'>These Sales Terms ("Terms") govern the sale of source code ("Product") by Barbotine ("Seller," "we," "us," or "our") to the individual or entity purchasing the Product ("Buyer," "you," or "your"). By purchasing the Product, you agree to be bound by these Terms.</p>
                            <h2 className='legal-subtitle'>2. Product License</h2>
                            <p className='legal-p'>Upon purchase, you are granted a non-exclusive, non-transferable, worldwide license to use the Product for personal, non-commercial purposes only. This license is subject to the following restrictions:</p>
                            <p className='legal-p'>You may not:</p>
                            <p className='legal-p' style={{ marginLeft: 20 }}>- Resell, sublicense, distribute, or otherwise commercially exploit the Product.</p>
                            <p className='legal-p' style={{ marginLeft: 20 }}>- Remove or alter any copyright, trademark, or other proprietary notices on the Product.</p>
                            <h2 className='legal-subtitle'>3. Payment & delivery</h2>
                            <p className='legal-p'>Payment for the Product is due at the time of purchase. Once payment is processed, you will receive instructions on how to access and download the Product.</p>
                            <h2 className='legal-subtitle'>4. Refunds</h2>
                            <p className='legal-p'>Due to the digital nature of the Product, all sales are final and no refunds will be issued.</p>
                            <h2 className='legal-subtitle'>5. Disclaimer of Warranties</h2>
                            <p className='legal-p'>THE PRODUCT IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE SELLER DOES NOT WARRANT THAT THE PRODUCT WILL MEET YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE PRODUCT WILL BE UNINTERRUPTED OR ERROR-FREE.</p>
                            <h2 className='legal-subtitle'>6. Limitation of Liability</h2>
                            <p className='legal-p'>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE SELLER BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE PRODUCT.</p>
                            <h2 className='legal-subtitle'>7. Governing Law and Dispute Resolution</h2>
                            <p className='legal-p'>These Terms shall be governed by and construed in accordance with the laws of France. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts of France.</p>
                            <h2 className='legal-subtitle'>8. Entire Agreement</h2>
                            <p className='legal-p'>These Terms constitute the entire agreement between you and the Seller relating to the subject matter herein and supersede all prior or contemporaneous communications and proposals, whether oral or written. Date: June 21, 2024</p>
                        </div>}
                    </div>
                </div>
                <div className='legal-div'>
                    <div onClick={() => { setTos(!Tos) }} className='legal-title-div'>
                        <h1 style={{ margin: 0, fontWeight: 500 }}>Terms of use</h1>
                        <svg style={{ transition: '0.1s', transform: !Tos ? 'rotate(-90deg)' : '' }} fill="#000000" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" /></svg>
                    </div>
                    <div className={`legal-content ${Tos ? 'opened' : ''}`}>
                        {Tos && <div className='legal-main-container'>
                            <h2 className='legal-subtitle'>1. Acceptance of Terms</h2>
                            <p className='legal-p'>By accessing or using this website, you agree to be bound by these Terms of Use ("Terms"). If you disagree with any part of the Terms, then you may not access the website.</p>
                            <h2 className='legal-subtitle'>2. Use of website</h2>
                            <p className='legal-p'>You may use this website for lawful purposes only. You may not use this website: In any way that violates any applicable federal, state, local, or international law or regulation. To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail," "chain letter," "spam," or any other similar solicitation. To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the website, or which, as determined by us, may harm Barbotine or users of the website or expose them to liability.</p>
                            <h2 className='legal-subtitle'>3. Intellectual Property Rights</h2>
                            <p className='legal-p'>The content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Barbotine or its licensors and is protected by copyright, trademark, and other intellectual property laws.</p>
                            <h2 className='legal-subtitle'>4. Disclaimer</h2>
                            <p className='legal-p'>The materials on Barbotine's website are provided "as is." Barbotine makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
                            <h2 className='legal-subtitle'>5. Limitation of Liability</h2>
                            <p className='legal-p'>In no event shall Barbotine or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Barbotine's website, even if Barbotine or a Barbotine authorized representative has been notified orally or in writing of the possibility of such damage.</p>
                            <h2 className='legal-subtitle'>6. Governing Law</h2>
                            <p className='legal-p'>These Terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions.</p>
                            <h2 className='legal-subtitle'>7. Entire Agreement</h2>
                            <p className='legal-p'>These Terms constitute the entire agreement between you and Barbotine relating to your use of this website and supersede all prior or contemporaneous communications and proposals, whether oral or written.</p>
                            <h2 className='legal-subtitle'>8. Trademark notice</h2>
                            <p className='legal-p'>Barbotine is a trademark of Barbotine SASU. All other trademarks, service marks, and logos used on this website are the property of their respective owners.</p>
                            <h2 className='legal-subtitle'>9. Changes to These Terms of Use</h2>
                            <p className='legal-p'>We may revise these Terms of Use at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms of Use. Date: June 21, 2024</p>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Legal
import React from 'react';
import { Helmet } from 'react-helmet';
import '../main.css';
import Footer from '../components/Footer';
import { useState } from 'react'
import { useMediaQuery } from '@react-hook/media-query';

function Contact() {

    const isMobile = useMediaQuery('(max-width: 768px)');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        setStatus('Envoi en cours...');

        try {
            const response = await fetch('/.netlify/functions/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_name: name, user_email: email, user_message: message }),
            });

            if (response.ok) {
                setStatus('Message envoyé !');
                setName('');
                setEmail('');
                setMessage('');
            } else {
                setStatus('Erreur lors de l’envoi du message.');
            }
        } catch (error) {
            console.error('Erreur lors de l’envoi du message :', error);
            setStatus('Erreur lors de l’envoi du message.');
        }
    };

    return (
        <div className='page-container'>
            <Helmet>
                <title>Contact | Barbotine</title>
                <meta property="og:title" content="Contact | Barbotine" />
            </Helmet>
            <div className='main-container' style={{ marginTop: isMobile ? '100px' : '', height:'fit-content' }}>
                <h1 style={{ textAlign:'center', marginTop:50 }}>Contact</h1>
                <form className='contact-div' onSubmit={handleSubmit}>
                    <div className='form-input-div'>
                        <label htmlFor="name">Your name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-input-div'>
                        <label htmlFor="email">Your e-mail</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className='form-input-div'>
                        <label htmlFor="message">Your message</label>
                        <textarea
                            id="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            spellCheck={false}
                            style={{ height:'250px' }}
                        />
                    </div>
                    <div style={{ display:'grid',width:'100%', marginBottom:100 }}><button style={{ width:'fit-content', justifySelf:'center' }} className='home-link' type="submit">Send message</button></div>
                    {status && <p>{status}</p>}
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Contact
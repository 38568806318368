import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';

import '../main.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { useMediaQuery } from '@react-hook/media-query';
import { loadStripe } from '@stripe/stripe-js';
import Wrapper from '../components/Wrapper'
import { Elements } from '@stripe/react-stripe-js';
import Feature from '../components/Feature'
import ScrollButton from '../components/ScrollButton';

const stripePromise = loadStripe('pk_live_51NOQhnHGEh1NJwWz0mVC4L1nudU3jk1po35WyE1GqDzzg368CcTwSioImW0pucNvMp39GvXak0AHncnV0WRLtHpa00LSZnWYPn');

function Pricing({ title_page }) {

    const isMobile = useMediaQuery('(max-width: 768px)');
    const SmallScreen = useMediaQuery('(max-width: 1200px)');
    const [clientSecret, setClientSecret] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({ firstName: '', lastName: '', email: '', github: '', discord: '', selectedBot: '' })
    const [isPaymentDone, setIsPaymentDone] = useState(false);
    const [ipAddress, setIPAddress] = useState('')
    const [capitalPriceY, setCapitalPriceY] = useState(70)
    const [capitalPriceM, setCapitalPriceM] = useState(80)
    const [singlePriceM, setSinglePriceM] = useState(40)
    const [singlePriceY, setSinglePriceY] = useState(50)
    // const [selectedBot, setSelectedBot] = useState('barbotine-arbitrage')
    const [pricingSelected, setPricingSelected] = useState('capital')
    // const [durationSingle, setDurationSingle] = useState(365)
    const [durationCapital, setDurationCapital] = useState(365)
    const [CapitalPrice, setCapitalPrice] = useState(80)
    // const [SinglePrice, setSinglePrice] = useState(50)
    const myRef = useRef(null);
    const [showPaymentContainer, setShowPaymentContainer] = useState(false)

    const changePricingSelected = (number) => {
        setPricingSelected(number);
        setShowPaymentContainer(true)
    }
    const returnToPricing = () => {
        setClientSecret(null)
        setShowPaymentContainer(false)
    }
    // const handleSelectedBotChange = (e) => {
    //     setSelectedBot(e.target.value);
    // };
    const handleSelectedDurationCapital = (e) => {
        setDurationCapital(parseFloat(e.target.value));
    };
    // const handleSelectedDurationSingle = (e) => {
    //     setDurationSingle(parseFloat(e.target.value));
    // };

    useEffect(() => {
        setCapitalPrice(durationCapital === 30 ? capitalPriceM : capitalPriceY);
        console.log(CapitalPrice)
    }, [capitalPriceM, capitalPriceY, durationCapital, CapitalPrice])

    // useEffect(() => {
    //     setSinglePrice(selectedBot === "barbotine-ohlcv" ? 0 : (durationSingle === 30 ? singlePriceM : singlePriceY))
    // }, [singlePriceM, singlePriceY, durationSingle])

    useEffect(() => {
        fetch('https://geolocation-db.com/json/')
            .then(response => response.json())
            .then(data => setIPAddress(data.IPv4))
            .catch(error => console.log(error))
        fetch('/.netlify/functions/get-price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceOfWhat: 'CAPITAL_PRICE_YEAR' }),
        })
            .then((res) => res.json())
            .then((data) => setCapitalPriceY(parseFloat(data.price)))
            .catch(error => (console.log(error)));
        fetch('/.netlify/functions/get-price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceOfWhat: 'SINGLE_PRICE_YEAR' }),
        })
            .then((res) => res.json())
            .then((data) => setSinglePriceY(parseFloat(data.price)))
            .catch(error => (console.log(error)));
        fetch('/.netlify/functions/get-price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceOfWhat: 'CAPITAL_PRICE_MONTH' }),
        })
            .then((res) => res.json())
            .then((data) => setCapitalPriceM(parseFloat(data.price)))
            .catch(error => (console.log(error)));
        fetch('/.netlify/functions/get-price', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceOfWhat: 'SINGLE_PRICE_MONTH' }),
        })
            .then((res) => res.json())
            .then((data) => setSinglePriceM(parseFloat(data.price)))
            .catch(error => (console.log(error)));
    }, [setIPAddress, setCapitalPriceY, setSinglePriceY, setCapitalPriceM, setSinglePriceM]);

    const handleUserDataChange = (e) => {
        let s = e.target.id
        if (s === 'firstName') {
            setUserData({ ...userData, firstName: e.target.value });
        }
        if (s === 'lastName') {
            setUserData({ ...userData, lastName: e.target.value });
        }
        if (s === 'github') {
            setUserData({ ...userData, github: e.target.value });
        }
        if (s === 'discord') {
            setUserData({ ...userData, discord: e.target.value });
        }
        if (s === 'email') {
            setUserData({ ...userData, email: e.target.value });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {

            const agent = navigator.userAgent;

            await fetch('/.netlify/functions/create-payment-intent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ browserAgent: agent, ipAddress: ipAddress, amount: CapitalPrice, name: userData.firstName + ' ' + userData.lastName, email: userData.email, github: userData.github, discord: userData.discord, selectedBot: 'barbotine-arbitrage*barbotine-dwm*barbotine-funding*barbotine-ohlcv*barbotine-pump-spike-detector*barbotine-correlation*barbotine-terminal-public', duration: durationCapital }),
                // body: JSON.stringify({ browserAgent: agent, ipAddress: ipAddress, amount: pricingSelected === 'capital' ? CapitalPrice : SinglePrice, name: userData.firstName + ' ' + userData.lastName, email: userData.email, github: userData.github, discord: userData.discord, selectedBot: pricingSelected === 'capital' ? 'barbotine-arbitrage*barbotine-dwm*barbotine-funding*barbotine-ohlcv*barbotine-pump-spike-detector*barbotine-correlation*barbotine-terminal-public' : selectedBot, duration: pricingSelected === "capital" ? durationCapital : durationSingle }),
            })
                .then((res) => res.json())
                .then((data) => setClientSecret(data.clientSecret));

            const offsetTop = myRef.current.offsetTop - 200
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });

        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const appearance = {
        theme: 'default',
        variables: {
            colorPrimary: 'black',
            colorBackground: '#ffffff',
            colorText: '#000000',
            colorDanger: '#df1b41',
            fontFamily: 'Open Sans',
            fontSmooth: 'always',
            focusOutline: "none",
            spacingUnit: '5px'
        },
        rules: {
            '*': {
                fontFamily: 'Open Sans',
                outline: 'none'
            },
            '*:focus-visible': {
                outline: 'none'
            },
            '*:active': {
                outline: 'none'
            },
            '.u-color-primary': {
                color: 'black'
            },
            '.Label': {
                color: '#000000',
            }
        }
    };

    const options = {
        clientSecret,
        appearance,
        fonts: [
            {
                family: "Open Sans",
                src: `url('https://fonts.cdnfonts.com/s/14884/OpenSans-Regular.woff')`,
            }
        ]
    };

    return (
        <div className='page-container'>
            <Helmet>
                <title>{`${title_page} | Barbotine`}</title>
                <meta property="og:title" content={`${title_page} | Barbotine`} />
            </Helmet>
            <ScrollButton />
            <div className='main-container' style={{ marginTop: isMobile ? '100px' : '' }}>
                <div className='main-pricing'>
                    <div className='pricing-header'>
                        <h1 className='home-title'>{title_page}</h1>
                        <p style={{ fontSize: 18, width: '60%', alignSelf: 'center' }}>All options are one-time payments, no harrassing recurring payments. You pay and never hear from us again.</p>
                    </div>
                    <div className='pricing-container'>
                        <div className={`offers-container ${!showPaymentContainer ? 'active' : ''}`}>

                            <div style={{ cursor: clientSecret ? "not-allowed" : "" }} className={`price1`}>
                                <div>
                                    <h2 className='pricing-title'>Arbitrage demo</h2>
                                    <h1 className='pricing-price'><span style={{ fontSize: 32 }}>€</span> 0</h1>
                                    <div className='features-list'>
                                        <Feature text={'Paper money for testing'} />
                                        <Feature text={'Real orderbook rates'} />
                                        <Feature text={'Works the same way as real mode'} />
                                    </div>
                                </div>
                                <Link to='https://github.com/nelso0/barbotine-arbitrage-bot' target="_blank" rel="noopener noreferrer" style={{ gap: 15, marginTop: 30, width: 'calc(100% - 65px)' }} className='home-link-basic'>Download demo {!SmallScreen && <img src='/images/arrow.svg' alt='arrow-redirect' />}</Link>
                            </div>

                            {/* <div style={{ cursor: clientSecret ? "not-allowed" : "" }} className={`price2`}>
                                <div>
                                    <h2 className='pricing-title'>Single</h2>
                                    <h1 className='pricing-price'><span style={{ fontSize: 32 }}>€</span> {SinglePrice}</h1>
                                    <div className='features-list'>
                                        <Feature text={'One bot'} />
                                        <Feature text={`Updates & support for ${durationSingle} days`} />
                                    </div>
                                    <select value={selectedBot} onChange={(e) => { handleSelectedBotChange(e) }}>
                                        <option value={'barbotine-arbitrage'}>Arbitrage bot</option>
                                        <option value={'barbotine-dwm'}>DWM kit</option>
                                        <option value={'barbotine-funding'}>Funding rates bot</option>
                                        <option value={'barbotine-terminal-public'}>Barbotine Terminal</option>
                                        <option value={'barbotine-pump-spike-detector'}>Notifier bot</option>
                                        <option value={'barbotine-ohlcv'}>OHLCV fetcher bot (free)</option>
                                    </select>
                                    <select value={durationSingle} onChange={(e) => { handleSelectedDurationSingle(e) }}>
                                        <option value={30}>30 days</option>
                                        <option value={365}>365 days</option>
                                    </select>
                                </div>
                                <button style={{ width: '100%', marginTop: 50, alignSelf: 'flex-end' }} onClick={() => { changePricingSelected('single') }} className='home-link-basic'>Get started</button>
                            </div> */}

                            <div style={{ cursor: clientSecret ? "not-allowed" : "" }} className={`price3`}>
                                <div>
                                    <h2 className='pricing-title'>Capital</h2>
                                    <h1 className='pricing-price'><span style={{ fontSize: 32 }}>€</span> {CapitalPrice}</h1>
                                    <div className='features-list'>
                                        <Feature text={'Full source code'} />
                                        <Feature text={'4 additional scripts'} />
                                        <Feature text={`Full support for ${durationCapital} days`} />
                                        <Feature text={'All DWM data by API'} />
                                        <Feature text={'Private chatrooms & alerts'} />
                                        <Feature text={'Special role in the Discord server'} />
                                    </div>
                                    <select value={durationCapital} onChange={(e) => { handleSelectedDurationCapital(e) }}>
                                        <option value={30}>30 days</option>
                                        <option value={365}>365 days</option>
                                    </select>
                                </div>
                                <button style={{ width: '100%', marginTop: 50 }} onClick={() => { changePricingSelected('capital') }} className='home-link'>Get started</button>
                            </div>

                        </div>
                        <div className={`payment-container ${showPaymentContainer ? 'active' : ""}`} ref={myRef}>
                            <button className='return-button' onClick={() => { returnToPricing() }}><svg className='return-button-svg' version="1.1" xmlns="http://www.w3.org/2000/svg" width="18.4961" height="14.8145">  <g>   <rect height="14.8145" opacity="0" width="18.4961" x="0" y="0" /><path d="M0 7.40234C0 7.64648 0.107422 7.87109 0.302734 8.05664L6.78711 14.5312C6.98242 14.7168 7.1875 14.8047 7.42188 14.8047C7.90039 14.8047 8.28125 14.4531 8.28125 13.9648C8.28125 13.7305 8.19336 13.4961 8.03711 13.3496L5.84961 11.123L1.98242 7.59766L1.77734 8.07617L4.92188 8.27148L17.2754 8.27148C17.7832 8.27148 18.1348 7.91016 18.1348 7.40234C18.1348 6.89453 17.7832 6.5332 17.2754 6.5332L4.92188 6.5332L1.77734 6.72852L1.98242 7.2168L5.84961 3.68164L8.03711 1.45508C8.19336 1.29883 8.28125 1.07422 8.28125 0.839844C8.28125 0.351562 7.90039 0 7.42188 0C7.1875 0 6.98242 0.078125 6.76758 0.292969L0.302734 6.74805C0.107422 6.93359 0 7.1582 0 7.40234Z" fill="black" /></g></svg>Return to pricing</button>
                            {!clientSecret && !isLoading && <form onSubmit={handleSubmit} className="form">
                                <div className='form-container'>
                                    <div className='first-form-div'>
                                        <div style={{ margin: 0 }} className="form-input-div">
                                            <label for="first-name">First name <span style={{ color: 'red' }}>*</span></label>
                                            <input onChange={handleUserDataChange} value={userData.firstName} autocomplete="off" className='input-capital' type="text" name="first-name" id="firstName" placeholder={'James'} required />
                                        </div>
                                        <div className="form-input-div">
                                            <label for="last-name">Last name <span style={{ color: 'red' }}>*</span></label>
                                            <input onChange={handleUserDataChange} value={userData.lastName} autocomplete="off" className='input-capital' type="text" name="last-name" id="lastName" placeholder={'Bond'} required />
                                        </div>
                                        <div className="form-input-div">
                                            <label for="email">E-mail <span style={{ color: 'red' }}>*</span></label>
                                            <input onChange={handleUserDataChange} value={userData.email} autocomplete="off" className='input-capital' type="text" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address" name="email" id="email" placeholder={'jamesbond@sis.gov.uk'} required />
                                        </div>
                                        <div className="form-input-div">
                                            <label for="github">Github username <span style={{ color: 'red' }}>*</span></label>
                                            <input onChange={handleUserDataChange} value={userData.github} autocomplete="off" className='input-capital' placeholder={'Q-department'} name="github" id="github" required />
                                        </div>
                                        <div className="form-input-div">
                                            <label for="discord">Discord username</label>
                                            <input onChange={handleUserDataChange} value={userData.discord} autocomplete="off" className='input-capital' placeholder={'jamesbond123'} name="discord" id="discord" />
                                        </div>
                                    </div>
                                    <div className='second-form-div'>
                                        <p style={{ marginTop: 30 }}>Subtotal:</p>
                                        <h1 style={{ marginTop: 0 }} className='capital-header-title'>€ {CapitalPrice} EUR</h1>
                                        <p style={{ marginTop: 10 }}>You will receive a collaborator invitation from GitHub by email to access the bots.</p>
                                        <button disabled={isLoading || userData.github === '' || userData.email === '' || userData.lastName === '' || userData.firstName === '' || typeof (capitalPriceM) !== 'number' || typeof (capitalPriceY) !== 'number' || typeof (singlePriceY) !== 'number' || typeof (singlePriceM) !== 'number'} className="home-link" style={{ width: 'fit-content', marginTop: 50 }} type="submit">
                                            Purchase now
                                        </button>
                                    </div>
                                </div>
                            </form>}
                            {!isPaymentDone && clientSecret && (
                                <Elements stripe={stripePromise} options={options}>
                                    <Wrapper isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} ></Wrapper>
                                </Elements>
                            )}
                            {!clientSecret && isLoading && <div className='loading-div'><div style={{ padding: 3 }} className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={201} height={201} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '25', height: '25' }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="5" stroke="#000000" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div><h2>Loading...</h2></div>}
                            {isPaymentDone && !isLoading && pricingSelected === 'capital' && <div className={`success-div ${isPaymentDone && !isLoading ? '' : 'not-shown'}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#0acf87<' d="M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z" /></svg><h2>Payment successful!</h2><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>You will receive a Github invitation for all the private repositories of Barbotine's bots. All the links are in the Discord server.</p><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>If the Discord account with the username you provided is already in Barbotine's Discord server, the Capital role will be added automatically. If you're not already in the Discord server or you don't provided a Discord username, no worries, you can contact me so I add you manually.</p><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>Github username: <i>{userData.github}</i></p><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>Discord username: <i>{userData.discord}</i></p><p style={{ width: '75%', marginBottom: 20, alignSelf: 'center' }} className='home-box-p'>E-mail address: <i>{userData.email}</i></p></div>}
                            {/* {isPaymentDone && !isLoading && pricingSelected === 'single' && <div className={`success-div ${isPaymentDone && !isLoading ? '' : 'not-shown'}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#0acf87<' d="M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z" /></svg><h2>Payment successful!</h2><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>You will receive a Github invitation for the private repository of this bot: {selectedBot}.</p><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>Github username: <i>{userData.github}</i></p><p style={{ width: '75%', alignSelf: 'center' }} className='home-box-p'>Discord username: <i>{userData.discord}</i></p><p style={{ width: '75%', marginBottom: 20, alignSelf: 'center' }} className='home-box-p'>E-mail address: <i>{userData.email}</i></p></div>} */}
                        </div>
                    </div>
                    <div className='third-card' style={{ backgroundColor: 'white' }}>
                        <div className='third-card-div1' style={{ paddingLeft: isMobile ? '0' : '', paddingRight: isMobile ? '0' : '' }}>
                            <h1 className='home-title' style={{ fontSize: 36 }}>Still have a question?</h1>
                            <p>If you still have a question, don't hesitate to ask us in the Discord community! You can also check out the documentation, a lot is explained there.</p>
                        </div>
                        <div className='third-card-div2' style={{ paddingLeft: isMobile ? '0' : '', paddingRight: isMobile ? '0' : '' }}>
                            <Link to='https://barbotine.xyz/community' className='home-link'>Join the community</Link>
                            <Link to='/docs' className='home-link-basic'>Official documentation</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Pricing

import './footer.css'
import { Link } from 'react-router-dom';

function Footer() {

    // const isMobile = useMediaQuery('(max-width: 768px)');
    const currentYear = new Date().getFullYear();

    return (
        <div className='footer-container'>
            <div className='logo-container'>
                <Link to='https://barbotine.xyz/community'><img alt='discord-logo' height={25} src='/images/discord.svg' /></Link>
                <Link to='https://www.youtube.com/@nelsorex/'><img alt='youtube-logo' height={25} src='/images/youtube.svg' /></Link>
                <Link to='https://medium.com/@barbotine'><img alt='medium-logo' height={25} src='/images/medium.svg' /></Link>
                <Link to='https://twitter.com/thebarbotine'><img alt='twitter-x-logo' height={25} src='/images/twitter.svg' /></Link>
            </div>
            <p className='copyright-text' style={{ margin:0 }}>Copyright © {currentYear} Barbotine. All rights reserved.</p>
            <Link to='/legal' className='header-link copyright-text'>Legal documents</Link>
        </div>
    )
}

export default Footer

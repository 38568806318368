import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import '../main.css';
import '../docs.css'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ScrollButton from '../components/ScrollButton';
import Tex2SVG from "react-hook-mathjax";
// import { useMediaQuery } from '@react-hook/media-query';

function Docs() {

    // const isMobile = useMediaQuery('(max-width: 768px)');
    const [SelectedSubSection, setSelectedSubSection] = useState(1);
    const sectionRefs = useRef([]);

    const handleSectionClick = (sectionId, section) => {
        const offsetTop = section ? sectionRefs.current[sectionId - 1].offsetTop - 150 : sectionRefs.current[sectionId - 1].offsetTop - 50;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        sectionRefs.current = sectionRefs.current.slice(0, 9);

        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            sectionRefs.current.forEach((ref, index) => {
                const sectionTop = ref.offsetTop;
                const sectionHeight = ref.offsetHeight;

                if (false) {
                    setSelectedSubSection(1);
                }

                else {
                    if (scrollPosition >= sectionTop * 0.8 && scrollPosition < sectionTop + sectionHeight) {
                        setSelectedSubSection(index + 1);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='main-container'>
            <Helmet>
                <title>Docs | Barbotine</title>
                <meta property="og:title" content="Docs | Barbotine" />
            </Helmet>
            <ScrollButton />
            <div className='main-bot'>
                <div className='navmenu'>
                    <h2 onClick={() => handleSectionClick(1, 1)} className={`section-title${SelectedSubSection <= 3 ? '-selected' : ''}`} style={{ marginTop:10 }}>Presentation</h2>
                    <div className='subsection-div'>
                        <Link onClick={() => handleSectionClick(1)} className={`subsection-p${SelectedSubSection === 1 ? '-selected' : ''}`}>What is it?</Link>
                        <Link onClick={() => handleSectionClick(2)} className={`subsection-p${SelectedSubSection === 2 ? '-selected' : ''}`}>More technical details</Link>
                        <Link onClick={() => handleSectionClick(3)} className={`subsection-p${SelectedSubSection === 3 ? '-selected' : ''}`}>Presentation by video</Link>
                    </div>
                    <h2 onClick={() => handleSectionClick(4, 4)} className={`section-title${SelectedSubSection <= 6 && SelectedSubSection > 3 ? '-selected' : ''}`}>Installation & usage</h2>
                    <div className='subsection-div'>
                        <Link onClick={() => handleSectionClick(4)} className={`subsection-p${SelectedSubSection === 4 ? '-selected' : ''}`}>Installation guide</Link>
                        <Link onClick={() => handleSectionClick(5)} className={`subsection-p${SelectedSubSection === 5 ? '-selected' : ''}`}>Usage</Link>
                        <Link onClick={() => handleSectionClick(6)} className={`subsection-p${SelectedSubSection === 6 ? '-selected' : ''}`}>Configuration</Link>
                    </div>
                    <h2 onClick={() => handleSectionClick(7, 7)} className={`section-title${SelectedSubSection <= 9 && SelectedSubSection > 6 ? '-selected' : ''}`}>Strategy & FAQ</h2>
                    <div className='subsection-div'>
                        <Link onClick={() => handleSectionClick(7)} className={`subsection-p${SelectedSubSection === 7 ? '-selected' : ''}`}>FAQ</Link>
                        <Link onClick={() => handleSectionClick(8)} className={`subsection-p${SelectedSubSection === 8 ? '-selected' : ''}`}>Rebalancing & hedging</Link>
                        <Link onClick={() => handleSectionClick(9)} className={`subsection-p${SelectedSubSection === 9 ? '-selected' : ''}`}>Things to do next</Link>
                        <Link to='https://documentation.barbotine.xyz' target="_blank" rel="noopener noreferrer" className={`subsection-p${SelectedSubSection === 10 ? '-selected' : ''}`}>Old documentation <img src="/images/arrow.svg" alt="arrow-redirect" /></Link>
                    </div>

                </div>
                <div className='documentation-div'>
                    <div className='main-description' id='1'>
                        <h1 className='doc-section-title'>Presentation</h1>
                        <bar />
                        <section ref={el => sectionRefs.current[0] = el}>
                            <h1 className='bot-subtitle'>1/ What is it?</h1>
                            
                            <p className='bot-p'>Barbotine's arbitrage bot is a cross-exchange crypto arbitrage system that you can run on any machine running Python 3. It's built on top of an open-source library, <Link className='doc-link' target="_blank" rel="noopener noreferrer" to='https://github.com/ccxt/ccxt'>CCXT</Link>, which manages the connectors to exchanges' APIs and offer a centralized way to connect to more than 50 crypto exchanges.</p>
                            <p className='bot-p'>The demo mode is available for free on <Link className='doc-link' to='https://github.com/nelso0/barbotine-arbitrage-bot' target="_blank" rel="noopener noreferrer">Github</Link>, it works with paper money, fake money, so you can test and see how it works. There is a full version that works live with real money on real crypto markets. Note that the demo mode might show different results than live mode due to market factors like filling of orders that can't be simulated with fake money.</p>
                            <p className='bot-p'>The bot has an embedded strategy featuring a no-transfer rebalancing system, meaning that it's able to take cross-exchange arbitrage opportunities without transferring the coins from one exchange to another.</p>
                            <p className='bot-p'>The bot can work simultaneousely with an infinite number of exchanges (even if opportunities only involve two exchanges), but the rebalancing system means that it can only handle one pair at a time. You can run multiple instances of the bot, but you'd have to allocate proportionally less money on each individual instance.</p>
                            <p className='bot-p'>The infrastructure of the bot is pretty simple: a file named <little-code>main.py</little-code> is like the orchestra conductor, it launchs the bot script (either <little-code>bot.py</little-code> for the full version or <little-code>bot-fake-money.py</little-code> for the demo version) with the <Link className='doc-link' target="_blank" rel="noopener noreferrer" to='https://docs.python.org/3/library/subprocess.html'>subprocess Python module</Link>. When you want to run the bot, you use the file named <little-code>run.py</little-code>, which calls the <little-code>main.py</little-code> script.</p>
                        </section>
                        <section ref={el => sectionRefs.current[1] = el}>
                            <h1 className='bot-subtitle'>2/ More technical details</h1>
                            
                            <p className='bot-p'>Barbotine's arbitrage bot uses an asynchronous loop to fetch orderbooks faster on all exchanges simultaneousely (using websockets).</p>
                            <p className='bot-p'>It logs everything in a logs folder, all taken opportunities and information/errors in separate <little-code>.txt</little-code> files, using simple Python functions to write in <little-code>.txt</little-code> files.</p>
                            <p className='bot-p'>The bot works with what I call 'sessions'. At the end of each session, a rebalancing happens (<Link className='doc-link' onClick={() => handleSectionClick(7)}>See more details about rebalancing</Link>). You have to input a session renewal delay, after this delay, it will end the current session and start a new one. This cycle is repeated indefinitely unless you stop the bot. You can also disable automatic session renewal in the config file (<little-code>exchange_config.py</little-code>). You can also trigger a manual session renewal by typing 'Enter' on your keyboard when in a session.</p>
                        </section>
                        <section ref={el => sectionRefs.current[2] = el}>
                            <h1 className='bot-subtitle'>3/ Presentation by video</h1>
                            
                            <iframe src="https://www.youtube.com/embed/-ps4VVLjn1o?si=WALw4M0xlyOJTxt0&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                        </section>
                    </div>
                    <div className='main-description' id='2'>
                        <h1 className='doc-section-title'>Installation and usage</h1>
                        <bar />
                        <section ref={el => sectionRefs.current[3] = el}>
                            <h1 className='bot-subtitle'>1/ Installation guide</h1>
                            
                            <p className='bot-p'>1. Clone the Github repository (or download the zip file from Github website)</p>
                            <code>git clone https://github.com/nelso0/barbotine-arbitrage-bot</code>
                            <p className='bot-p'>2. Go to the folder in your terminal/cmd</p>
                            <code>cd barbotine-arbitrage-bot</code>
                            <p className='bot-p'>3. Install the required Python modules</p>
                            <code>pip install -r requirements.txt</code>
                            <p className='bot-p'>4. Set your configuration details in <little-code>exchange_config.py</little-code></p>
                            <p className='bot-p'>5. Run with:</p>
                            <code>python run.py</code>
                        </section>
                        <section ref={el => sectionRefs.current[4] = el}>
                            <h1 className='bot-subtitle'>2/ Usage</h1>
                            
                            <p className='bot-p'>You can run it directly with <little-code>python run.py</little-code>, and the bot will ask you the parameters, but you can be quicker and run it direclty with the parameters as arguments, just like this:</p>
                            <code>python run.py <span style={{ color: 'darkgreen' }}>&lt;mode&gt;</span> [renew-time-minutes] <span style={{ color: 'darkgreen' }}>&lt;balance&gt;</span> <span style={{ color: 'darkgreen' }}>&lt;pair&gt;</span> <span style={{ color: 'darkgreen' }}>&lt;exchanges list separated by commas (no space!)&gt;</span></code>
                            <p className='bot-p'>Some details about the parameters:</p>
                            <p className='bot-p'><little-code>mode</little-code> --&gt; The mode you wanna use between 'fake-money' or 'real'</p>
                            <p className='bot-p'><little-code>renew-time-minutes</little-code> --&gt; The time in minutes that sessions should last. Only applicable if renewal enabled.</p>
                            <p className='bot-p'><little-code>balance</little-code> --&gt; The total balance in quote currency (most of the time in USDT) you want the bot to run with.</p>
                            <p className='bot-p'><little-code>pair</little-code> --&gt; The pair you want the bot to run on. Should be the exact same on all exchanges.</p>
                            <p className='bot-p'><little-code>exchanges-list</little-code> --&gt; The list of exchanges you want the bot to run on. Each should be configured in the config.</p>
                            <p className='bot-p'>Examples of usage:</p>
                            <code>python run.py fake-money 60 1000 SOL/USDT binance,okx,kucoin <span style={{ color: 'darkgrey', marginLeft: 30 }}># run the bot with paper money with renewal enabled happening every 60 min. on Binance, OKX and Kucoin, on the SOL/USDT pair.</span></code>
                            <code>python run.py real 3500 ETH/BTC poloniex,bitmex,bingx <span style={{ color: 'darkgrey', marginLeft: 30 }}># run the bot with real money on Bitmex, Poloniex and BingX, on the ETH/BTC pair.</span></code>
                        </section>
                        <section ref={el => sectionRefs.current[5] = el}>
                            <h1 className='bot-subtitle'>3/ Configuration</h1>
                            
                            <p className='bot-p'>In this part, we're gonna breakdown every setting of <little-code>exchange_config.py</little-code>.</p>
                            <p className='bot-p'><span style={{ color: 'blue', fontSize: 16 }}>*</span> = Mandatory for demo and real modes</p>
                            <p className='bot-p'><span style={{ color: 'red', fontSize: 16 }}>*</span> = Mandatory only for real mode</p>
                            <table>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10, borderTop:0 }}><p className='bot-p-config'><little-code>renewal</little-code><span style={{ color: 'blue' }}>*</span></p></th><td style={{ borderTop:0 }}><p className='bot-p'> (boolean) You can enable or disable renewal. If you enable it, you need to specify a renewal delay when running the bot (see <Link className='doc-link' onClick={() => handleSectionClick(5)}>Usage</Link>). Default: False</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>delta_neutral</little-code><span style={{ color: 'red' }}>*</span></p></th><td><p className='bot-p'> (boolean) Enable if you want to hedge the purchase of cryptos for rebalancing with a short order, to remain in a delta-neutral situation. If enabled, you need to fill in the necessary configuration in the file <little-code>delta_neutral_config.py</little-code>. More details in <Link className='doc-link' onClick={()=>{handleSectionClick(8)}}>Rebalancing & hedging</Link>.</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>python_command</little-code><span style={{ color: 'blue' }}>*</span></p></th><td><p className='bot-p'> (string) The command you put in the terminal/cmd to launch python. Usually: python, python3, py...</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>exchanges</little-code><span style={{ color: 'blue' }}>*</span></p></th><td><p className='bot-p'> (dict) It's a dictionnary in a dictionnary. The key is exchange's ID (like <Link className='doc-link' target="_blank" rel="noopener noreferrer" to='https://github.com/ccxt/ccxt'>here</Link>), and value is a dictionnary containing necessary API details of that exchange for CCXT. Most of the time, it's this: <little-code>&#123;'apiKey':'here','secret':'here'&#125;</little-code> and replace 'here' with your API credentials. Some exchanges need other parameters, just include them in this dictionnary.</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>apiToken</little-code></p></th><td><p className='bot-p'>(string) Telegram API details to send everything to you, don't fill unless you want telegram notifications. If so, this is the apiToken of your telegram bot.</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>criteria_pct</little-code></p></th><td><p className='bot-p'>(float) Minimum of price difference in % to take the opportunity. Default: 0</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>criteria_usd</little-code></p></th><td><p className='bot-p'>(float) Minimum of profit in quote currency (= the second asset of the pair, most of the time USDT). Default: 0</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>first_orders_fill_timeout</little-code></p></th><td><p className='bot-p'>(float) Put a value for the timeout in minutes of the rebalancing orders filling. 0 means deactivated, it means it waits indefinitely for the orders to get filled (default).</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>demo_fake_delay</little-code></p></th><td><p className='bot-p'>(boolean) Enable or disable a fake ping delay to better simulate the order filling (see <Link className='doc-link' onClick={() => {handleSectionClick(7)}}>Strategy & FAQ</Link> for more details). Default: False</p></td></tr>
                                <tr><th style={{ display: 'flex', flexDirection: 'row', gap: 10 }}><p className='bot-p-config'><little-code>demo_fake_delay_ms</little-code></p></th><td><p className='bot-p'>(int) Necessary if <little-code>demo_fake_delay</little-code> is enabled. The fake ping delay in milliseconds. Note that if enabled, the bot will have to fetch the orderbooks again, which will take time anyway, even if you put this value at 0. <little-code>demo_fake_delay_ms</little-code> is additional delay. For example, if you enable <little-code>demo_fake_delay</little-code>, put <little-code>demo_fake_delay_ms</little-code> at 0, and fetching an orderbook on your machine takes 1 second, the bot will technically add a 1 second fake delay, because fetching orderbooks again isn't instantaneous.</p></td></tr>
                            </table>
                        </section>
                    </div>
                    <div className='main-description' id='3'>
                        <h1 className='doc-section-title'>Strategy & FAQ</h1>
                        <bar />
                        <section ref={el => sectionRefs.current[6] = el}>
                            <h1 className='bot-subtitle'>1/ FAQ</h1>
                            
                            <p className='bot-p'><b>What is the difference between demo/fake-money mode and real mode?</b></p>
                            <p className='bot-p'>As its title says, the fake-money mode works with a fake balance, and the real mode actually sends orders with your real account balance on the real markets. The fake-money/demo mode simulates everything including trading fees (for both rebalancing orders and opportunity orders) and works with the same prices as the real mode (best bid/best ask).</p>
                            <p className='bot-p'>The bot currently uses market orders to take opportunities (more details in the <Link className='doc-link' onClick={() => handleSectionClick(8)}>next section "Strategy"</Link>), so the fake-money/demo mode assumes you have a 0ms ping delay and that you get filled at the best bid/ask price fetched when the order is sent. You can enable a setting to simulate a custom ping-delay between the time at which the price is fetched and the time at which the fake order is received and executed by the exchange (see demo_fake_delay in <Link className='doc-link' onClick={()=>{handleSectionClick(6)}}>Configuration</Link>).</p>
                            <p className='bot-p'><b>How much profit can I except?</b></p>
                            <p className='bot-p'>It depends on you, you have to ask this question to yourself and your strategy. The point of this open-source bot is to make it yours. With this bot, I tried to make your life as easier as I can, but I can't do the job for you (and nobody can). When to run it, where to run it, how to run it, with what settings, and why... These are questions you need to answer in order to be profitable. You can see it as a hard task, but in my opinion, it's the beauty of algo-trading. Anyone can create their own strategy and it has to be unique. You are rewarded for creating your own path, isn't it beautiful?</p>
                            <p className='bot-p'><b>I have errors in the terminal/cmd but the bot doesn't stop, is it a bug?</b></p>
                            <p className='bot-p'>No, as the bot works with asynchronous websockets to fetch the orderbooks at a very fast pace, some exchanges can close the connection unexpectedly. That's why Barbotine's arbitrage bot has a built-in automatic reconnecting system that handles the reconnection for you. The errors like <little-code>ConnectionResetError</little-code> are simply messages telling you that the connection was closed 'brutally', unexpectedly. It's exactly the same as when you pull out an SD card without ejecting it first, it's harmless.</p>
                        </section>
                        <section ref={el => sectionRefs.current[7] = el}>
                            <h1 className='bot-subtitle'>2/ Rebalancing & hedging</h1>
                             
                            <p className='bot-p'>As said in <Link className='doc-link' onClick={() => {handleSectionClick(2)}}>Technical details</Link>, the bot rebalances automatically each X seconds (if you enable <little-code>renewal</little-code>). When you start the bot, you only need the quote currency of the pair you choosed. For example, if you run the bot on BTC/USDT, you only need USDT in all exchanges. Before starting the bot, each exchange should have:</p>
                            <div style={{ marginTop:30, marginBottom:30 }}><Tex2SVG display="inline" latex="\textrm{balance}_\textrm{per exchange} = \frac{\textrm{total}\hspace{0.15cm}\textrm{balance}}{\textrm{number}\hspace{0.15cm}\textrm{of}\hspace{0.15cm}\textrm{exchanges}}\hspace{0.2cm} \tiny{(in\hspace{0.08cm}quote\hspace{0.08cm}currency)}" /></div>
                            <p className='bot-p'>For example, if you run the bot with 1000 as total balance, on the BTC/USDT pair with 4 exchanges, you need to put 1000/4 = 250 USDT in each exchange (spot wallet).</p>
                            <p className='bot-p'>To take opportunities, the bot doesn't transfer the coins. It means it has to buy & sell on both exchanges. This way, we can avoid transfer fees and delay. But in order to do that, we need to have quote-currency AND base-currency. For example, with the BTC/USDT pair, we have to own USDT and BTC in each exchange, to be able to buy BTC on exchange 1, and sell BTC on exchange 2. So, when you start the bot, it will automatically buy base-currency (BTC) on each exchange, with the half of quote-currency (USDT) that you put in it before. At each rebalancing/start of the bot, the balances will look like this:</p>
                            <img draggable={false} height={300} className='img-doc' src='/images/rebalancing.svg' alt='rebalancing'></img>
                            <p className='bot-p'>This is what it will look like with 3 exchanges, 750 total balance, on the BTC/USDT pair. When an opportunity arises, it will calculate all possible opportunities, and the impact on the balances (including all fees). If it's profitable and match <little-code>criteria_usd</little-code> and <little-code>criteria_pct</little-code>, it immediately starts sending the orders. Here's a schema of what happens to the balances of the 2 exchanges:</p>
                            <img draggable={false} height={450} className='img-doc' src='/images/opportunity.svg' alt='opportunity'></img>
                            <p className='bot-p'>Assuming you want profits in quote-currency (most of the time USDT) and not in base-currency, at the each rebalancing, the bot sells everything back to quote-currency, and buys the appropriate quantity of base-currency to end up having the necessary balances to take opportunities (first schema). This would be perfect for a pair like USDC/USDT, because the ratio always stays equal to 1. But for any other volatile pair like BTC/USDT, if BTC goes down, you'll money when rebalancing happens. That's why Barbotine's arbitrage bot integrates a "delta-neutral" feature. This allows you to configure a futures/perpetual exchange, to automatically place a short order with the appropriate quantity, close it when rebalancing happens, and repeat. Here's a schema describing how the hedging works:</p>
                            <img draggable={false} height={450} className='img-doc' src='/images/hedging.svg' alt='hedgine'></img>
                        </section>
                        <section ref={el => sectionRefs.current[8] = el}>
                            <h1 className='bot-subtitle'>3/ Things to do next</h1>
                            
                            <p className='bot-p'>- Rewrite it in other languages such as JS</p>
                            <p className='bot-p'>- Add an UI</p>
                        </section>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Docs

import React from 'react';
import { Helmet } from 'react-helmet';
import '../main.css';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { useRef } from 'react'
import { useMediaQuery } from '@react-hook/media-query';
import ScrollButton from '../components/ScrollButton';

function Home() {

    const isMobile = useMediaQuery('(max-width: 768px)');

    const myRef = useRef(null)
    const executeScroll = () => {
        const offsetTop = isMobile ? myRef.current.offsetTop - 100 : myRef.current.offsetTop + 800
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    }

    return (
        <div className='page-container'>
            <Helmet>
                <title>Home | Barbotine</title>
                <meta property="og:title" content="Home | Barbotine" />
            </Helmet>
            <ScrollButton />
            <div className='main-container' style={{ marginTop: isMobile ? '100px' : '' }}>
                <div className='first-card-div1' style={{ alignItems: 'center', textAlign: 'center', alignSelf: 'center', justifySelf: 'center' }}>
                    <h1 className='home-title'>The most complete <span style={{ textWrap: 'nowrap' }}>open-source</span> arbitrage system.</h1>
                    <p style={{ color: 'black', fontSize: 20, lineHeight: '35px' }} className='home-p'>Barbotine's arbitrage system is an automated arbitrage bot operating on crypto markets. It's entirely written in Python and able to identify the smallest arbitrage opportunity on orderbooks of 50+ exchanges at the same time.</p>
                    <div className='first-card-buttons-row'>
                        <Link onClick={executeScroll} className='home-link-wow'>Learn more</Link>
                        <Link to='/pricing' className='home-link-basic'>See pricing</Link>
                    </div>
                </div>
                {/* <div className='first-card'>
                        <div className='first-card-div1'>
                            <h1 className='home-title'>The most complete <span style={{ textWrap:'nowrap' }}>open-source</span> arbitrage system.</h1>
                            <p className='home-p'>Barbotine's arbitrage system is an automated arbitrage bot operating on crypto markets. It's entirely written in Python and able to identify the smallest arbitrage opportunity on orderbooks of 50+ exchanges at the same time.</p>
                            <div className='first-card-buttons-row'>
                                <Link onClick={scrollToSecond} className='home-link'>Learn more</Link>
                                <Link to='/pricing' className='home-link-basic'>See pricing</Link>
                            </div>
                        </div>
                        <div className='first-card-div2'>
                            <img className='barbotine-robot' src='/images/barbotine-robot.svg' alt='barbotine-robot' />
                        </div>
                    </div> */}
                <div className='second-card'>
                    <div className="second-card-div1" ref={myRef}>
                        <h1 className='home-title' style={{ color: 'black' }}>Why choose Barbotine?</h1>
                    </div>
                    <div className="second-card-wrapper">
                        <div className='second-card-wrap1'>
                            <h2 style={{ color: 'black' }}>Automated arbitrage execution</h2>
                            <p style={{ color: '#00000059' }}>Our bot continuously scans the market, identifies profitable trades, and executes them automatically, according to your settings.</p>
                        </div>
                        <div className='second-card-wrap2'>
                            <h2 style={{ color: 'black' }}>Multi-exchange integration</h2>
                            <p style={{ color: '#00000059' }}>With CCXT, our bot is connected to over <b>70</b> crypto exchanges (<Link style={{ color: '#22222259' }} className='home-link-text' target="_blank" rel="noopener noreferrer" to='https://github.com/ccxt/ccxt'>full list here</Link>), allowing it to identify and capitalize on price discrepancies across all crypto markets at the same time.</p>
                        </div>
                        <div className='second-card-wrap3'>
                            <h2 style={{ color: 'black' }}>Ready-2-Run</h2>
                            <p style={{ color: '#00000059' }}>Minimal configuration is required to start the bot for the first time. You can kickstart your arbitrage journey in less than 5 minutes.</p>
                        </div>
                        <div className='second-card-wrap4'>
                            <h2 style={{ color: 'black' }}>Security is not a choice</h2>
                            <p style={{ color: '#00000059' }}>As we give you the whole source-code, <b>you and only you</b> have access to your exchanges API credentials. It's technically impossible for us or someone else to steal your data, because the arbitrage system is <b>self-hosted</b>.</p>
                        </div>
                        <div className='second-card-wrap5'>
                            <h2 style={{ color: 'black' }}>Customizable strategy</h2>
                            <p style={{ color: '#00000059' }}>Tailor the bot's arbitrage strategies to your preferences and market conditions. The Python code is made to be easily editable, there is one main file with a clear structure.</p>
                        </div>
                        <div className='second-card-wrap6'>
                            <h2 style={{ color: 'black' }}>24/7 VPS included</h2>
                            <p style={{ color: '#00000059' }}>To run our bot 24/7 without interruption, Barbotine gives you an optimized Linux VPS for the bot, with no additionnal fee for one month. If you need it, just ask for it!</p>
                        </div>
                    </div>
                </div>
                <div className='one-half-card'>
                    <div className='one-half-card-div1'>
                        <h1 className='home-title'>From zero to arbitrageur</h1>
                        <p className='home-p'>Here are all steps to run Barbotine's arbitrage system from scratch.</p>
                    </div>
                    <div className='one-half-card-div2'>
                        <div className='one-half-card-grid'>
                            <div className='step1'>
                                <numero>2 min</numero>
                                <h2 className='step-title'>1. Get Python</h2>
                                <p>Install Python on your machine</p>
                            </div>
                            <div className='step2'>
                                <numero>30 sec</numero>
                                <h2 className='step-title'>2. Download the arbitrage bot</h2>
                                <p>Download the repository from Github</p>
                            </div>
                            <div className='step3'>
                                <numero>20 sec</numero>
                                <h2 className='step-title'>3. Install requirements</h2>
                                <p>Install the Python requirements for the bot</p>
                            </div>
                            <div className='step4'>
                                <numero>3 min</numero>
                                <h2 className='step-title'>4. Configure your APIs</h2>
                                <p>Put your exchanges’ API credentials in the configuration file</p>
                            </div>
                            <div className='step5'>
                                <numero>10 sec</numero>
                                <h2 className='step-title'>5. Choose your pair</h2>
                                <p>It should be the same and existing on all exchanges</p>
                            </div>
                            <div className='step6'>
                                <numero>15 sec</numero>
                                <h2 className='step-title'>6. Run it!</h2>
                                <p>And watch the bot monitor markets @ 100 updates per second while sitting down in your couch :)</p>
                            </div>
                            <div className='final-step'>
                                <div className='final-time'><img src='/images/clock-icon.svg' alt='clock-icon' width={20}></img>Total: 6 minutes, 15 seconds</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='third-card'>
                    <div className='third-card-div1'>
                        <h1 className='home-title' style={{ fontSize: 36 }}>Still have a question?</h1>
                        <p>If you still have a question, don't hesitate to ask us in the Discord community! You can also check out the documentation, a lot is explained there!</p>
                    </div>
                    <div className='third-card-div2'>
                        <Link to='https://barbotine.xyz/community' className='home-link'>Join the community</Link>
                        <Link to='/docs' className='home-link-basic'>Official documentation</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home

import './Marquee.css'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@react-hook/media-query';

function Marquee({text}) {
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (
        <div className="wrapper" style={{ backgroundColor:isMobile?'transparent':'' }}>
            {!isMobile && <div className="marquee">
                <Link to='https://robuxio.com?via=nelso'>
                    {text}
                    <img src='/images/arrow-white.svg' alt='redirect-arrow' style={{ height:12, fill:'white', marginLeft:5 }} />
                </Link>
            </div>}
        </div>
    )
}

export default Marquee

import { Route, Routes, Navigate } from "react-router-dom"
import React, {useEffect} from "react";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Docs from "./pages/Docs";
import Legal from "./pages/Legal";
// import Dwm from "./pages/Dwm";
import './main.css'

const RedirectPage = ({link}) => {
  React.useEffect(() => {
    window.location.replace(link)
  }, [link])
  return null
}

export default function App() {
  useEffect(() => {
      document.body.style.backgroundColor = '#ffffff';
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (themeColorMeta!==null) {
        themeColorMeta.setAttribute('content', '#FFFFFF');
      }
      const otherOne = document.querySelector('link[rel="mask-icon"]');
      if (otherOne!==null) {
        otherOne.setAttribute('color', '#FFFFFF');
      }
      const otherotherOne = document.querySelector('meta[name="msapplication-TileColor"]');
      if (otherotherOne!==null) {
        otherotherOne.setAttribute('content', '#FFFFFF');
      }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing title_page={'Get started'}></Pricing>} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/get-started" element={<Pricing title_page={'Get started'}></Pricing>} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/dwm" element={<RedirectPage link={'https://dwm.barbotine.xyz'} />} />
      <Route path="/delta-weighted-median" element={<RedirectPage link={'https://dwm.barbotine.xyz'} />} />
      <Route path="/dwm-live" element={<RedirectPage link={'https://dwm.barbotine.xyz'} />} />
      <Route path="/dwm-indicator" element={<RedirectPage link={'https://dwm.barbotine.xyz'} />} />
      <Route path="/documentation" element={<Docs />} />
      <Route path='/discord' element={<RedirectPage link={'https://discord.gg/WCEtDTZ5sh'} />}/>
      <Route path='/community' element={<RedirectPage link={'https://discord.gg/WCEtDTZ5sh'} />}/>
      <Route path='/scalping-docs' element={<RedirectPage link={'https://docs.google.com/document/d/1MEE9_6A2Q2Mg1FI7GyPTdKrEnhX0DFb3MrxPTtVT8C0/'} />}/>
      <Route path='/funding-docs' element={<RedirectPage link={'https://docs.google.com/document/d/1RhihKYZkNdzLP6WNfo0StcYEnhu_PBuy-SBxHK-wYqY/'} />}/>
      <Route path='/ohlcv-docs' element={<RedirectPage link={'https://docs.google.com/document/d/1rNVNtZf-TfGZXnqVIXPTHkbekdzlWhw7TQAW_JtU0mg/'} />}/>
      <Route path='/notifier-docs' element={<RedirectPage link={'https://docs.google.com/document/d/1fv0107-5BARK-l4TtMtBWqxSkztmXXfDfM-D_GuirIg/'} />}/>
      <Route path='/terminal-docs' element={<RedirectPage link={'https://docs.google.com/document/d/1ToH0EpKrIzy7KzECYKpbJfDvqo94XTs8fGxZEDIG-PA/'} />}/>
      <Route path='/legal' element={<Legal />}/>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
